import {createContext, useCallback, useEffect, useState} from "react";

const defaultValue = {
	showDescription: false,
	showConstructionType: false
};

export class AnimatedAppearanceValue {
	constructor(originValue, setValue) {
		this._value = originValue;
		this._setValue = setValue;
	}

	showDescription() {
		return this._value.showDescription;
	}

	showConstructionType() {
		return this._value.showConstructionType;
	}

	withUpdatedDescription({show}) {
		return new AnimatedAppearanceValue(
			{...this._value, showDescription: show},
			this._setValue
		);
	}

	withUpdatedConstructionType({show}) {
		return new AnimatedAppearanceValue(
			{...this._value, showConstructionType: show},
			this._setValue
		);
	}

	toNodeTitleContent(treeItem) {
		const style = {
			marginLeft: '8px',
			backgroundColor: "#F2F4F5",
			outline: "1px solid",
			outlineColor: "#DBDBDB"
		}
		const result = [];

		if (this.showDescription()) {
			result.push(
				<span key="node-description" id="node-description" style={style}>
					{treeItem.description || "(empty)"}
				</span>
			);
		}

		if (this.showConstructionType()) {
			result.push(
				<span key="node-construction-type" id="node-construction-type" style={style}>
					{treeItem.constructionTypeName || "(empty)"}
				</span>
			);
		}

		if (treeItem.animated().supportsQuantity()) {
			result.push(
				<span key="node-quantity" id="node-quantity" style={style}>
					({treeItem.quantity})
				</span>
			);
		}

		return result;
	}

	saveChanges() {
		this._setValue(this._value);
	}
}

function empty() {

}

export const HierarchyAppearanceContext = createContext(new AnimatedAppearanceValue(defaultValue, empty));

export const HierarchyAppearanceProvider = ({children}) => {
	const [value, setValueInternal] = useState(defaultValue);

	useEffect(() => {
		let data;
		try {
			const persisted = localStorage.getItem('hierarchy_appearance');
			data = {...defaultValue, ...JSON.parse(persisted)};
		} catch {
			data = {...defaultValue};
		}
		setValueInternal(data);
	}, []);

	const setValue = useCallback((value) => {
		setValueInternal(value);
		localStorage.setItem('hierarchy_appearance', JSON.stringify(value));
	}, []);

	return (
		<HierarchyAppearanceContext.Provider value={new AnimatedAppearanceValue(value, setValue)}>
			{children}
		</HierarchyAppearanceContext.Provider>
	);
}
