import {
	ApiSelectedBomNode, ApiSelectedConstructionTypeNode,
	ApiSelectedEquipmentNode,
	ApiSelectedFlocNode, ApiSelectedMatChildNode,
	ApiSelectedMatNode, ApiSelectedPmPlanNode
} from "../selected-node/api/ApiSelectedNode";
import {BomIcon, ConstructionTypeIcon, FlocIcon, MaterialIcon} from "../../icons";
import {Dropdown, Menu} from "antd";
import {EhApi} from "../../../services";
import {useContext} from "react";
import {HierarchyActionsContext} from "../HierarchyActionsContext";
import {hierarchyNodes, hierarchyNodesWithId, parsedChildren} from "../Hierarchy";
import {SelectedNode} from "../selected-node/SelectedNode";
import {EquipmentIcon, PmPlanIcon} from "../../icons/hierarchy-icons";
import {SmartAttributes} from "../selected-node/SmartAttributes";
import {HierarchyAppearanceContext} from "../../../contexts/hierarchy-appearance-config/HierarchyAppearanceContext";
import {lastElementOf} from "../../../utils";
import {NODE_COLORS} from "../../../constants";


function FlocContextMenu({node, children}) {
	const {possibleFlocActions} = useContext(HierarchyActionsContext);

	return (
		<ContextMenuDropdown id={"node-context-menu-floc"} children={children} node={node} actions={possibleFlocActions}/>
	);
}


function EquipmentContextMenu({node, children}) {
	const {possibleEquipmentActions} = useContext(HierarchyActionsContext);

	return (
		<ContextMenuDropdown id={"node-context-menu-equipment"} children={children} node={node} actions={possibleEquipmentActions}/>
	);
}


function BomContextMenu({node, children}) {
	const {possibleBomActions} = useContext(HierarchyActionsContext);

	return (
		<ContextMenuDropdown id={"node-context-menu-bom"} children={children} node={node} actions={possibleBomActions}/>
	);
}


function ConstructionTypeContextMenu({node, children}) {
	const {possibleConstructionTypeActions} = useContext(HierarchyActionsContext);

	return (
		<ContextMenuDropdown
			id={"node-context-menu-construction-type"}
			children={children}
			node={node}
			actions={possibleConstructionTypeActions}
		/>
	);
}


function PmPlanContextMenu({node, children}) {
	const {possiblePmPlanActions} = useContext(HierarchyActionsContext);

	return (
		<ContextMenuDropdown
			id={"node-context-menu-pm-plan"}
			children={children}
			node={node}
			actions={possiblePmPlanActions}
		/>
	);
}


function MaterialContextMenu({node, children}) {
	const {possibleMaterialActions} = useContext(HierarchyActionsContext);

	return (
		<ContextMenuDropdown id={"node-context-menu-material"} children={children} node={node} actions={possibleMaterialActions}/>
	);
}


function ContextMenuDropdown({id, node, actions, children}) {
	return (
		<Dropdown disabled={node.isDisabled()} trigger={["contextMenu"]} overlay={(
			<Menu
				id={id}
				items={actions.map(action => action.toItem(node).data)}
				onClick={item => {
					actions
						.map(el => el.toItem(node))
						.filter(actionItem => actionItem.onItemClick)
						.forEach(a => a.onItemClick(item));
				}}
			/>
		)}>
			{children}
		</Dropdown>
	);
}


function NodeTitle({icon, text, treeItem, ...rest}) {
	return (
		<div
			{...rest}
			data-parent-node-id={treeItem.parent?.id}
			data-node-id={treeItem.id}
			style={{}}
		>
			<span style={{marginRight: '8px'}}>
				{icon}
			</span>
			{text}
		</div>
	);
}


function TitleTextFromTreeItem({treeItem, color}) {
	const settings = useContext(HierarchyAppearanceContext);
	return (
		<TitleText title={treeItem.entityId} rest={settings.toNodeTitleContent(treeItem)} color={color}/>
	);
}


function TitleText({title, rest, color}) {
	return (
		<span>
			<span
				id="node-title"
				className="node-text"
				style={{padding: '2px', borderRadius: '2px', backgroundColor: color}}
			>
				{title}
			</span>
			{rest}
		</span>
	);
}


export function BomNode(bom, parentKey, setSelectedNode, parent) {
	const treeItem = {
		id: bom.id,
		type: 'bom',
		isLeaf: bom.empty,
		children: [],
		parent: parent,
		disabled: parent?.disabled,
		entityId: bom.entity_id,
		isFullyLoaded: false,
		description: bom.description
	}

	treeItem.animated = () => {
		return new BomAnimatedNode(treeItem, setSelectedNode);
	}

	treeItem.animated().actualizeKey();
	treeItem.animated().updateTitle();
	return treeItem;
}


export function ConstructionTypeNode(constructionType, parentKey, setSelectedNode, parent) {
	const treeItem = {
		id: constructionType.id,
		type: 'construction_type',
		isLeaf: constructionType.empty,
		children: [],
		parent: parent,
		disabled: parent?.disabled,
		entityId: constructionType.entity_id,
		isFullyLoaded: false,
		description: constructionType.description
	}

	treeItem.animated = () => {
		return new ConstructionTypeAnimatedNode(treeItem, setSelectedNode);
	}

	treeItem.animated().actualizeKey();
	treeItem.animated().updateTitle();
	return treeItem;
}


export function MaterialNode(material, parentKey, setSelectedNode, parent) {
	const treeItem = {
		id: material.id,
		type: 'mat',
		isLeaf: material.empty,
		children: [],
		parent: parent,
		disabled: parent?.disabled,
		entityId: material.entity_id,
		isFullyLoaded: false,
		description: material.description,
		quantity: material.quantity,
		entityClass: {
			id: material.entity_class.id,
			name: material.entity_class.name,
		}
	}

	treeItem.animated = () => {
		return new MaterialAnimatedNode(treeItem, setSelectedNode)
	}

	treeItem.animated().actualizeKey();
	treeItem.animated().updateTitle();
	return treeItem;
}


export function PmPlanNode(pmPlan, parentKey, setSelectedNode, parent) {
	const treeItem = {
		id: pmPlan.id,
		type: 'pm_plan',
		isLeaf: pmPlan.empty,
		children: [],
		parent: parent,
		disabled: parent?.disabled,
		entityId: pmPlan.entity_id,
		isFullyLoaded: false,
		description: pmPlan.description,
		entityClass: {
			id: pmPlan.entity_class.id,
			name: pmPlan.entity_class.name,
		}
	}

	treeItem.animated = () => {
		return new PmPlanAnimatedNode(treeItem, setSelectedNode)
	}

	treeItem.animated().actualizeKey();
	treeItem.animated().updateTitle();
	return treeItem;
}


export function ExpandedNodeChild(child, parentKey, setSelectedNode, parent) {
	let entityClass = {};
	if (child.type === 'equipment') {
		entityClass = {
			entityClass: {
				id: child.entity_class.id,
				name: child.entity_class.name,
			}
		}
	}
	const treeNode = {
		id: child.id,
		type: child.type,
		isLeaf: !child.expandable,
		parent: parent,
		children: [],
		disabled: parent?.disabled,
		entityId: child.entity_id,
		isFullyLoaded: false,
		description: child.description,
		constructionTypeName: child.construction_type_name,
		...entityClass
	}

	treeNode.animated = () => {
		if (child.type === 'floc') {
			return new FlocAnimatedNode(treeNode, setSelectedNode);
		} else {
			return new EquipmentAnimatedNode(treeNode, setSelectedNode);
		}
	}

	treeNode.animated().actualizeKey();
	treeNode.animated().updateTitle();
	return treeNode;
}


export function NodePathOf(id, entityId) {
	return new NodePath().with({id, entityId});
}


export class NodePath {
	constructor(value= []) {
		this._value = value;
	}

	parts() {
		return this._value.map((_, idx) => new NodePath(this._value.slice(0, idx+1)));
	}

	asString() {
		return this._value.map(el => el.id).join("/");
	}

	act(nodes, action) {
		let found;
		for (const node of nodes) {
			if (node.animated().pathIs(this)) {
				 found = node;
			}
		}
		if (found) {
			action(found);
		}
	}

	with(treeItem) {
		return new NodePath(
			[...this._value, {id: treeItem.id, entityId: treeItem.entityId}]
		);
	}

	title() {
		const tail = lastElementOf(this._value);
		return tail?.entityId ?? '';
	}

	updateTitle(value) {
		const tail = lastElementOf(this._value);
		return new NodePath([...this._value.slice(0, -1), {id: tail.id, entityId: value}]);
	}

	equalsTo(path) {
		return this.asString() === path.asString();
	}
}


class AdoptType {
	constructor(value, possibleParents) {
		this._value = value;
		this._possibleParents = possibleParents;
	}

	value() {
		return this._value;
	}

	canBeAdoptedBy(type) {
		return this._possibleParents.includes(type);
	}
}


export class NodeGroup {
	constructor(treeData, nodeId) {
		this._treeData = treeData;
		this._id = nodeId;
	}

	replaceWith(preview) {
		for (const same of hierarchyNodesWithId(this._treeData, this._id)) {
			same.animated().replaceWithInternal(preview);
		}
	}

	removeChildrenWithId(id) {
		for (const same of hierarchyNodesWithId(this._treeData, this._id)) {
			same.animated().removeChildrenWithId(id);
		}
	}

	addMaterial(preview) {
		for (const same of hierarchyNodesWithId(this._treeData, this._id)) {
			same.animated().addMaterialInternal(preview);
		}
	}

	addPmPlan(preview, setSelectedNode) {
		for (const same of hierarchyNodesWithId(this._treeData, this._id)) {
			const pmPlan = PmPlanNode(
				preview,
				same.key,
				setSelectedNode,
				same
			);

			same.animated().addChildInternal(pmPlan.animated());
		}
	}

	addBom(preview, setSelectedNode) {
		for (const same of hierarchyNodesWithId(this._treeData, this._id)) {
			const bom = BomNode(
				preview,
				same.key,
				setSelectedNode,
				same
			);

			same.animated().addChildInternal(bom.animated());
		}
	}
}


class BaseAnimatedNode {
	constructor(treeItem, adoptType, setSelectedNode) {
		this.treeItem = treeItem;
		this._adoptType = adoptType;
		this._setSelectedNode = setSelectedNode;
	}

	cut() {
		this.treeItem.disabled = true;
		this.children().forEach(c => c.cut());
	}

	uncut() {
		this.treeItem.disabled = false;
		this.children().forEach(c => c.uncut());
	}

	isDisabled() {
		return this.treeItem.disabled;
	}

	parent() {
		return this.treeItem.parent.animated();
	}

	children() {
		return this.treeItem.children.map(el => el.animated());
	}

	sortChildren() {
		const order = [
			'floc',
			'equipment',
			'bom',
			'construction_type',
			'pm_plan',
			'mat'
		];

		this.treeItem.children = this.treeItem.children.sort((a, b) => {
			const aOrder = order.findIndex(el => el === a.type);
			const bOrder = order.findIndex(el => el === b.type);

			if (aOrder === bOrder) {
				return a.entityId.localeCompare(b.entityId);
			} else {
				return aOrder - bOrder;
			}
		});
	}

	isParented() {
		return this.treeItem.parent != null;
	}

	parentKeys() {
		const keys = [];
		let parent = this.treeItem.parent;
		for (let i = 0; i < 100; i++) {
			if (parent == null) break;
			keys.push(parent.key);
			parent = parent.parent;
		}
		return keys;
	}

	pathIs(path) {
		return this.treeItem.path.equalsTo(path);
	}

	actualizeKey() {
		this.treeItem.key = (this.treeItem.parent?.key ?? '') + '/' + this.treeItem.id;
		const parentPath = this.treeItem.parent?.path ?? new NodePath();
		this.treeItem.path = parentPath.with(this.treeItem);
		this.children().forEach(c => c.actualizeKey());
	}

	addChildInternal(animatedChild) {
		if (this.treeItem.children.length === 0) {
			this.treeItem.isFullyLoaded = false;
		}

		this.treeItem.children.push(animatedChild.treeItem);
		this.sortChildren();
		animatedChild.treeItem.parent = this.treeItem;
		this.treeItem.isLeaf = false;
	}

	replaceWithInternal(preview) {
		const replaceTargetPreview = preview;
		if (this.isParented()) {
			const parent = this.parent();
			this.removeInternal();
			const replaceTarget = parent.children().find(c => c.idIs(replaceTargetPreview.id));
			if (!replaceTarget) {
				this.replaceWithSameTypeStrategy(parent, preview);
			}
		}
	}

	replaceWithSameTypeStrategy(parent, preview) {
		throw new Error('Not implemented');
	}

	addMaterialInternal(preview) {
		const material = MaterialNode(
			preview,
			this.treeItem.key,
			this._setSelectedNode,
			this.treeItem
		);
		this.addChildInternal(material.animated());
	}

	removeChildrenWithId(id) {
		const childrenWithId = this.children().filter(child => child.idIs(id));
		if (childrenWithId.length === 0) {
			this.treeItem.isLeaf = true;
		}

		for (const child of childrenWithId) {
			this.removeChildInternal(child);
		}
	}

	removeChildInternal(animatedChild) {
		this.treeItem.children = this.treeItem.children.filter(el => el !== animatedChild.treeItem);

		if (this.treeItem === animatedChild.treeItem.parent) {
			animatedChild.treeItem.parent = null;
		}

		if (this.treeItem.children.length === 0) {
			this.treeItem.isLeaf = true;
		}
	}

	removeInternal() {
		if (this.isParented()) {
			this.parent().removeChildrenWithId(this.treeItem.id);
		}
	}

	expandWithTreeItems(treeItems) {
		this.treeItem.children = treeItems;
		this.sortChildren();
		this.treeItem.isLeaf = this.treeItem.children.length === 0;
		this.treeItem.isFullyLoaded = true;
	}

	supportsQuantity() {
		return false;
	}

	async reparentWith(animatedParent) {
		throw new Error('Not implemented');
	}

	async assignMaterial(matId) {
		throw new Error('Not implemented');
	}

	async assignBom(bomId, copy) {
		throw new Error('Not implemented');
	}

	async removeMaterial(animatedMaterial) {
		throw new Error('Not implemented');
	}

	async removeBom(animatedBom) {
		throw new Error('Not implemented');
	}

	async createFloc(flocCreationData) {
		throw new Error('Not implemented');
	}

	async createEquipment(equipmentCreationData) {
		throw new Error('Not implemented');
	}

	async createAsMMParent(mmParentCreationData) {
		throw new Error('Not implemented');
	}

	async remove() {
		throw new Error('Not implemented');
	}

	async expandedInfo() {
		throw new Error('Not implemented');
	}

	async assignPmPlan(pmPlanId) {
		throw new Error('Not implemented');
	}

	async removePmPlan(animatedPmPlan) {
		throw new Error('Not implemented');
	}

	clearConstructionTypeNodeInternal() {
		throw new Error('Not implemented');
	}

	assignConstructionTypeNodeInternal(preview) {
		throw new Error('Not implemented');
	}

	select() {
		throw new Error('Not implemented');
	}

	ableToAdopt(animatedNode) {
		return animatedNode.canBeAdoptedBy(this._adoptType.value());
	}

	canBeAdoptedBy(adoptType) {
		return this._adoptType.canBeAdoptedBy(adoptType);
	}

	asAssignPmPlanSubject() {
		return {
			title: this.treeItem.entityId,
			handle: pmPlanId => this.assignPmPlan(pmPlanId)
		}
	}

	asAssignMaterialSubject() {
		return {
			title: this.treeItem.entityId,
			handle: matId => this.assignMaterial(matId)
		}
	}

	asAssignBomSubject() {
		return {
			title: this.treeItem.entityId,
			handle: bomId => this.assignBom(bomId, false)
		}
	}

	asCopyBomSubject() {
		return {
			title: this.treeItem.entityId,
			handle: bomId => this.assignBom(bomId, true)
		}
	}

	asCreateEquipmentSubject() {
		return {
			title: this.treeItem.entityId,
			handle: data => this.createEquipment(data)
		}
	}

	asCreateAsMMParentSubject() {
		return {
			originMatId: this.treeItem.id,
			handle: data => this.createAsMMParent(data)
		}
	}

	asCreateFlocSubject() {
		return {
			title: this.treeItem.entityId,
			handle: data => this.createFloc(data)
		}
	}

	idIs(id) {
		return this.treeItem.id === id;
	}

	parentIdIs(id) {
		if (this.isParented()) {
			return this.parent().treeItem.id === id;
		} else {
			return false;
		}
	}

	idIn(ids) {
		return ids.includes(this.treeItem.id);
	}

	typeIs(name) {
		return this._adoptType.value() === name;
	}

	parentTitle() {
		return this.treeItem.parent?.entityId ?? "";
	}

	updateConstructionTypeName(value) {
		this.treeItem.constructionTypeName = value;
		this.actualizeKey();
		this.updateTitle();
	}

	asSelectedNode() {
		return new SelectedNode();
	}

	updateTitle() {

	}

	updateDataInternal(editedData) {
		const smartAttributes = new SmartAttributes(editedData);
		smartAttributes.actualizeTreeItemFields(this.treeItem);
		this.actualizeKey();
		this.updateTitle();
	}

	async syncEditing(editedData) {
		return async treeData => {
			this.updateDataInternal(editedData);
		}
	}

	isGroupConstructionTypeAssignTarget(filters) {
		return false;
	}
}

class HierarchyAnimatedNode extends BaseAnimatedNode {
	constructor(treeItem, setSelectedNode, apiNamespace, adoptType) {
		super(treeItem, adoptType, setSelectedNode);
		this._setSelectedNode = setSelectedNode;
		this._apiNamespace = apiNamespace;
	}

	async reparentWith(animatedParent) {
		return new Promise((resolve, reject) => {
			EhApi.put(
				`/hierarchies/current/preview/nodes/${this.treeItem.id}/parent`,
				{'parent_id': animatedParent.treeItem.id}
			).then(_ => {
				resolve(async _ => {
					this.parent().removeChildInternal(this);
					animatedParent.addChildInternal(this);
					this.actualizeKey();
				});
			}).catch(reject);
		})
	}

	expandedInfo() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/hierarchies/current/preview/nodes/${this.treeItem.id}/expanded`
			).then(response => {
				resolve(response.data)
			}).catch(reject);
		});
	}

	async createFloc(flocCreationData) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				'/flocs',
				{...flocCreationData, parent_id: this.treeItem.id}
			).then(response => {
				resolve(async _ => {
					const node = ExpandedNodeChild(
						response.data,
						this.treeItem.key,
						this._setSelectedNode,
						this.treeItem
					)

					this.addChildInternal(node.animated());
				})
			}).catch(reject);
		});
	}

	async createEquipment(equipmentCreationData) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				'/equipments',
				{...equipmentCreationData, parent_id: this.treeItem.id}
			).then(response => {
				resolve(async _ => {
					const node = ExpandedNodeChild(
						response.data,
						this.treeItem.key,
						this._setSelectedNode,
						this.treeItem
					)

					this.addChildInternal(node.animated());
				})
			}).catch(reject);
		});
	}

	async assignBom(bomId, copy) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/${this._apiNamespace}/${this.treeItem.id}/boms`,
				{bom_id: bomId, copy}
			).then(response => {
				resolve(async _ => {
					const node = BomNode(
						response.data,
						this.treeItem.key,
						this._setSelectedNode,
						this.treeItem
					);

					this.addChildInternal(node.animated());
				});
			}).catch(reject);
		});
	}

	async removeBom(animatedBom) {
		return new Promise((resolve, reject) => {
			const bomId = animatedBom.treeItem.id;
			EhApi.delete(
				`/${this._apiNamespace}/${this.treeItem.id}/boms/${bomId}`,
			).then(_ => {
				resolve(async _ => {
					this.removeChildInternal(animatedBom);
				});
			}).catch(reject);
		});
	}

	async assignMaterial(matId) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/${this._apiNamespace}/${this.treeItem.id}/materials`,
				{material_id: matId}
			).then(response => {
				resolve(async _ => {
					const materialPreview = response.data;
					const node = MaterialNode(
						materialPreview,
						this.treeItem.key,
						this._setSelectedNode,
						this.treeItem
					);

					this.addChildInternal(node.animated());
				});
			}).catch(reject);
		});
	}

	async removeMaterial(animatedMaterial) {
		return new Promise((resolve, reject) => {
			const matId = animatedMaterial.treeItem.id;

			EhApi.delete(
				`/${this._apiNamespace}/${this.treeItem.id}/materials/${matId}`,
			).then(_ => {
				resolve(async _ => {
					this.removeChildInternal(animatedMaterial);
				});
			}).catch(reject);
		});
	}

	async assignPmPlan(pmPlanId) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/${this._apiNamespace}/${this.treeItem.id}/pm-plans`,
				{pm_plan_id: pmPlanId}
			).then(response => {
				resolve(async treeData => {
					new NodeGroup(treeData, this.treeItem.id).addPmPlan(response.data, this._setSelectedNode);
				});
			}).catch(reject);
		});
	}

	async removePmPlan(animatedPmPlan) {
		return new Promise((resolve, reject) => {
			const id = animatedPmPlan.treeItem.id;
			EhApi.delete(
				`/${this._apiNamespace}/${this.treeItem.id}/pm-plans/${id}`,
			).then(_ => {
				resolve(async treeData => {
					new NodeGroup(treeData, this.treeItem.id).removeChildrenWithId(id);
				});
			}).catch(reject);
		});
	}

	select() {
		this._setSelectedNode(this.asSelectedNode());
	}

	async syncEditing(editedData) {
		return async treeData => {
			this.updateDataInternal(editedData);
			return this.expandedInfo().then(expandedNode => {
				const key = this.treeItem.key;
				const setSelectedNode = this._setSelectedNode;
				const node = this.treeItem;

				const children = parsedChildren(expandedNode, key, setSelectedNode, node);
				this.expandWithTreeItems(children);
			})
		}
	}

	clearConstructionTypeNodeInternal() {
		this.children()
			.filter(child => child.typeIs("construction_type"))
			.forEach(child => {
				this.removeChildInternal(child)
			});
		this.updateConstructionTypeName(null);
	}

	assignConstructionTypeNodeInternal(preview) {
		const ctType = ConstructionTypeNode(
			preview,
			this.treeItem.key,
			this._setSelectedNode,
			this.treeItem
		)
		this.addChildInternal(ctType.animated());
		this.updateConstructionTypeName(preview.entity_id);
	}
}


class EquipmentAnimatedNode extends HierarchyAnimatedNode {
	constructor(treeItem, setSelectedNode) {
		super(
			treeItem,
			setSelectedNode,
			'equipments',
			new AdoptType('equipment', ['equipment', 'floc'])
		);
	}

	updateTitle() {
		this.treeItem.title = (
			<EquipmentContextMenu node={this}>
				<NodeTitle
					text={<TitleTextFromTreeItem treeItem={this.treeItem} color={NODE_COLORS.EQUIPMENT}/>}
					icon={<EquipmentIcon />}
					treeItem={this.treeItem}
				/>
			</EquipmentContextMenu>
		);
	}

	asSelectedNode() {
		return new ApiSelectedEquipmentNode(this.treeItem.id, this.treeItem.path);
	}

	isGroupConstructionTypeAssignTarget(filters) {
		return filters.class_id == this.treeItem.entityClass.id;
	}
}


class FlocAnimatedNode extends HierarchyAnimatedNode {
	constructor(treeNode, setSelectedNode) {
		super(treeNode, setSelectedNode, 'flocs', new AdoptType('floc', ['floc']));
	}

	asSelectedNode() {
		return new ApiSelectedFlocNode(this.treeItem.id, this.treeItem.path);
	}

	updateTitle() {
		this.treeItem.title = (
			<FlocContextMenu node={this}>
				<NodeTitle
					text={<TitleTextFromTreeItem treeItem={this.treeItem} color={NODE_COLORS.FLOC}/>}
					icon={<FlocIcon/>}
					treeItem={this.treeItem}
				/>
			</FlocContextMenu>
		);
	}
}


class MaterialAnimatedNode extends BaseAnimatedNode {
	constructor(treeItem, setSelectedNode) {
		super(
			treeItem,
			new AdoptType('material', ['equipment', 'floc', 'bom', 'construction_type']),
			setSelectedNode
		);
		this._setSelectedNode = setSelectedNode;
	}

	select() {
		this._setSelectedNode(this.asSelectedNode());
	}

	async createAsMMParent(mmParentCreationData) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/materials/${this.treeItem.id}/as-mm-parent`,
				mmParentCreationData
			).then(response => {
				resolve(async treeData => {
					const materialPreview = response.data;
					for (const same of hierarchyNodesWithId(treeData, this.treeItem.id)) {
						const animated = same.animated();

						if (animated.isParented()) {
							const parent = animated.parent();
							animated.removeInternal();

							const material = MaterialNode(
								materialPreview,
								parent.treeItem.key,
								this._setSelectedNode,
								parent.treeItem
							);

							parent.addChildInternal(material.animated());
						}
					}
				})
			}).catch(reject);
		});
	}

	async expandedInfo() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/materials/${this.treeItem.id}/expanded`
			).then(response => {
				const data = response.data;
				resolve(data);
			}).catch(reject);
		});
	}

	async removeMaterial(animatedMaterial) {
		return new Promise((resolve, reject) => {
			const matId = animatedMaterial.treeItem.id;

			EhApi.delete(
				`/materials/${this.treeItem.id}/nested_materials/${matId}`,
			).then(_ => {
				resolve(async treeData => {
					for (const same of hierarchyNodesWithId(treeData, this.treeItem.id)) {
						same.animated().removeChildrenWithId(matId);
					}
				});
			}).catch(reject);
		});
	}

	async assignMaterial(matId) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/materials/${this.treeItem.id}/nested_materials`,
				{material_id: matId}
			).then(response => {
				const addedMaterialPreview = response.data;
                EhApi.get(
                    `/materials/${this.treeItem.id}/preview`
                ).then(response => {
                    const thisMaterialPreview = response.data;
					resolve(async treeData => {
							for (const same of hierarchyNodesWithId(treeData, addedMaterialPreview.id)) {
								const subjectToReplace = same.animated();
								if (subjectToReplace.isParented()) {
									const parent = subjectToReplace.parent();
									subjectToReplace.removeInternal();
									const replaceTarget = parent.children().find(c => c.idIs(thisMaterialPreview.id));
									if (!replaceTarget) {
										const material = MaterialNode(
											thisMaterialPreview,
											parent.treeItem.key,
											this._setSelectedNode,
											parent.treeItem
										);
										parent.addChildInternal(material.animated());
									}
								}
							}
							for (const same of hierarchyNodesWithId(treeData, this.treeItem.id)) {
								const animated = same.animated();
								const material = MaterialNode(
									addedMaterialPreview,
									animated.treeItem.key,
									this._setSelectedNode,
									animated.treeItem
								);
								animated.addChildInternal(material.animated());
							}
						}
					)
                }).catch(reject);
			}).catch(reject);
		});
	}

	async syncEditing(editedData) {
		return async treeData => {
			for (const sameMaterial of hierarchyNodesWithId(treeData, this.treeItem.id)) {
				sameMaterial.animated().updateDataInternal(editedData);
			}
		}
	}

	asSelectedNode() {
		const id = this.treeItem.id;
		const path = this.treeItem.path;
		if (this.supportsQuantity()) {
			return new ApiSelectedMatChildNode(id, path, this.parent().treeItem.id);
		} else {
			return new ApiSelectedMatNode(id, path);
		}
	}

	supportsQuantity() {
		return this.isParented() && !this.parent().typeIs('material');
	}

	async remove() {
		return this.parent().removeMaterial(this);
	}

	replaceWithSameTypeStrategy(parent, preview) {
		preview.quantity = this.treeItem.quantity;
		parent.addMaterialInternal(preview);
	}

	updateTitle() {
		this.treeItem.title = (
			<MaterialContextMenu node={this}>
				<NodeTitle
					text={<TitleTextFromTreeItem treeItem={this.treeItem} color={NODE_COLORS.MATERIAL}/>}
					icon={<MaterialIcon/>}
					treeItem={this.treeItem}
				/>
			</MaterialContextMenu>
		);
	}
}


class BomAnimatedNode extends BaseAnimatedNode {
	constructor(treeItem, setSelectedNode) {
		super(
			treeItem,
			new AdoptType('bom', ['equipment', 'floc', 'bom', 'construction_type']),
			setSelectedNode
		);
		this._setSelectedNode = setSelectedNode;
	}

	expandedInfo() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/boms/${this.treeItem.id}/expanded`
			).then(response => {
				const data = response.data;
				resolve({
					...data,
					boms: data.nested_boms,
				});
			}).catch(reject);
		});
	}

	async remove() {
		return this.parent().removeBom(this);
	}

	async assignBom(bomId, copy) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/boms/${this.treeItem.id}/nested_boms`,
				{nested_bom_id: bomId, copy}
			).then(response => {
				resolve(async treeData => {
					for (const sameBom of hierarchyNodesWithId(treeData, this.treeItem.id)) {
						const bom = BomNode(
							response.data,
							sameBom.key,
							this._setSelectedNode,
							sameBom
						);

						sameBom.animated().addChildInternal(bom.animated());
					}
				});
			}).catch(reject);
		});
	}

	async removeBom(animatedBom) {
		return new Promise((resolve, reject) => {
			const bomId = animatedBom.treeItem.id;
			EhApi.delete(
				`/boms/${this.treeItem.id}/nested_boms/${bomId}`,
			).then(_ => {
				resolve(async treeData => {
					this.removeChildInternal(animatedBom);
					for (const sameBom of hierarchyNodesWithId(treeData, this.treeItem.id)) {
						sameBom.animated().removeChildrenWithId(bomId);
					}
				});
			}).catch(reject);
		});
	}

	async assignMaterial(matId) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/boms/${this.treeItem.id}/materials`,
				{material_id: matId}
			).then(response => {
				const materialPreview = response.data;
				resolve(async treeData => {
						for (const sameBom of hierarchyNodesWithId(treeData, this.treeItem.id)) {
							const material = MaterialNode(
								materialPreview,
								sameBom.key,
								this._setSelectedNode,
								sameBom
							);

							sameBom.animated().addChildInternal(material.animated());
						}
					}
				);
			}).catch(reject);
		});
	}

	async removeMaterial(animatedMaterial) {
		return new Promise((resolve, reject) => {
			const matId = animatedMaterial.treeItem.id;

			EhApi.delete(
				`/boms/${this.treeItem.id}/materials/${matId}`,
			).then(_ => {
				resolve(async treeData => {
					for (const sameBom of hierarchyNodesWithId(treeData, this.treeItem.id)) {
						sameBom.animated().removeChildrenWithId(matId);
					}
				});
			}).catch(reject);
		});
	}

	async syncEditing(editedData) {
		return async treeData => {
			for (const sameBom of hierarchyNodesWithId(treeData, this.treeItem.id)) {
				sameBom.animated().updateDataInternal(editedData);
			}
		}
	}

	select(selectPayload) {
		this._setSelectedNode(this.asSelectedNode());
	}

	asSelectedNode() {
		return new ApiSelectedBomNode(this.treeItem.id, this.treeItem.path);
	}

	updateTitle() {
		this.treeItem.title = (
			<BomContextMenu node={this}>
				<NodeTitle
					text={<TitleTextFromTreeItem treeItem={this.treeItem} color={NODE_COLORS.BOM}/>}
					icon={<BomIcon/>}
					treeItem={this.treeItem}
				/>
			</BomContextMenu>
		);
	}
}


class ConstructionTypeAnimatedNode extends BaseAnimatedNode {
	constructor(treeItem, setSelectedNode) {
		super(
			treeItem,
			new AdoptType('construction_type', ['equipment', 'floc']),
			setSelectedNode
		);
		this._setSelectedNode = setSelectedNode;
	}

	select(selectPayload) {
		this._setSelectedNode(this.asSelectedNode());
	}

	asSelectedNode() {
		return new ApiSelectedConstructionTypeNode(this.treeItem.id, this.treeItem.path);
	}

	expandedInfo() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/construction-types/${this.treeItem.id}/expanded`
			).then(response => {
				const data = response.data;
				resolve({
					...data,
					boms: data.nested_boms,
				});
			}).catch(reject);
		});
	}


	async assignBom(bomId, copy) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/construction-types/${this.treeItem.id}/nested_boms`,
				{nested_bom_id: bomId, copy}
			).then(response => {
				resolve(async treeData => {
					for (const same of hierarchyNodesWithId(treeData, this.treeItem.id)) {
						const bom = BomNode(
							response.data,
							same.key,
							this._setSelectedNode,
							same
						);

						same.animated().addChildInternal(bom.animated());
					}
				});
			}).catch(reject);
		});
	}

	async removeBom(animatedBom) {
		return new Promise((resolve, reject) => {
			const bomId = animatedBom.treeItem.id;
			EhApi.delete(
				`/construction-types/${this.treeItem.id}/nested_boms/${bomId}`,
			).then(_ => {
				resolve(async treeData => {
					this.removeChildInternal(animatedBom);
					for (const same of hierarchyNodesWithId(treeData, this.treeItem.id)) {
						same.animated().removeChildrenWithId(bomId);
					}
				});
			}).catch(reject);
		});
	}

	async assignMaterial(matId) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/construction-types/${this.treeItem.id}/materials`,
				{material_id: matId}
			).then(response => {
				const materialPreview = response.data;
				resolve(async treeData => {
						for (const same of hierarchyNodesWithId(treeData, this.treeItem.id)) {
							const material = MaterialNode(
								materialPreview,
								same.key,
								this._setSelectedNode,
								same
							);

							same.animated().addChildInternal(material.animated());
						}
					}
				);
			}).catch(reject);
		});
	}

	async removeMaterial(animatedMaterial) {
		return new Promise((resolve, reject) => {
			const matId = animatedMaterial.treeItem.id;

			EhApi.delete(
				`/construction-types/${this.treeItem.id}/materials/${matId}`,
			).then(_ => {
				resolve(async treeData => {
					for (const same of hierarchyNodesWithId(treeData, this.treeItem.id)) {
						same.animated().removeChildrenWithId(matId);
					}
				});
			}).catch(reject);
		});
	}

	async assignPmPlan(pmPlanId) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/construction-types/${this.treeItem.id}/pm-plans`,
				{pm_plan_id: pmPlanId}
			).then(response => {
				resolve(async treeData => {
					new NodeGroup(treeData, this.treeItem.id).addPmPlan(response.data, this._setSelectedNode);
				});
			}).catch(reject);
		});
	}

	async removePmPlan(animatedPmPlan) {
		return new Promise((resolve, reject) => {
			const id = animatedPmPlan.treeItem.id;
			EhApi.delete(
				`/construction-types/${this.treeItem.id}/pm-plans/${id}`,
			).then(_ => {
				resolve(async treeData => {
					new NodeGroup(treeData, this.treeItem.id).removeChildrenWithId(id);
				});
			}).catch(reject);
		});
	}

	async syncEditing(editedData) {
		return async treeData => {
			for (const sameConstructionType of hierarchyNodesWithId(treeData, this.treeItem.id)) {
				sameConstructionType.animated().updateDataInternal(editedData);
			}

			return this.asSelectedNode().parents().then(parents => {
				const parentIds = parents.map(p => p.id);
				for (const node of hierarchyNodes(treeData)) {
					const animatedNode = node.animated();
					if (animatedNode.idIn(parentIds)) {
						animatedNode.updateConstructionTypeName(this.treeItem.entityId);
					}
				}
			});
		}
	}

	updateTitle() {
		this.treeItem.title = (
			<ConstructionTypeContextMenu node={this}>
				<NodeTitle
					text={<TitleTextFromTreeItem treeItem={this.treeItem} color={NODE_COLORS.CONSTRUCTION_TYPE}/>}
					icon={<ConstructionTypeIcon/>}
					treeItem={this.treeItem}
				/>
			</ConstructionTypeContextMenu>
		);
	}
}


class PmPlanAnimatedNode extends BaseAnimatedNode {
	constructor(treeItem, setSelectedNode) {
		super(
			treeItem,
			new AdoptType('pm_plan', ['equipment', 'floc', 'construction_type']),
			setSelectedNode
		);
		this._setSelectedNode = setSelectedNode;
	}

	async assignBom(bomId, copy) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/pm-plans/${this.treeItem.id}/boms`,
				{bom_id: bomId, copy}
			).then(response => {
				resolve(async treeData => {
					new NodeGroup(treeData, this.treeItem.id).addBom(response.data, this._setSelectedNode);
				});
			}).catch(reject);
		});
	}

	async removeBom(animatedBom) {
		return new Promise((resolve, reject) => {
			const bomId = animatedBom.treeItem.id;
			EhApi.delete(
				`/pm-plans/${this.treeItem.id}/boms/${bomId}`,
			).then(_ => {
				resolve(async treeData => {
					new NodeGroup(treeData, this.treeItem.id).removeChildrenWithId(bomId);
				});
			}).catch(reject);
		});
	}

	async assignMaterial(matId) {
		return new Promise((resolve, reject) => {
			EhApi.post(
				`/pm-plans/${this.treeItem.id}/materials`,
				{material_id: matId}
			).then(response => {
				const materialPreview = response.data;
				resolve(async treeData => {
					new NodeGroup(treeData, this.treeItem.id).addMaterial(materialPreview);
				});
			}).catch(reject);
		});
	}

	async removeMaterial(animatedMaterial) {
		return new Promise((resolve, reject) => {
			const matId = animatedMaterial.treeItem.id;

			EhApi.delete(
				`/pm-plans/${this.treeItem.id}/materials/${matId}`,
			).then(_ => {
				resolve(async treeData => {
					new NodeGroup(treeData, this.treeItem.id).removeChildrenWithId(matId);
				});
			}).catch(reject);
		});
	}

	async remove() {
		return this.parent().removePmPlan(this);
	}

	select(selectPayload) {
		this._setSelectedNode(this.asSelectedNode());
	}

	asSelectedNode() {
		return new ApiSelectedPmPlanNode(this.treeItem.id, this.treeItem.path);
	}

	expandedInfo() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/pm-plans/${this.treeItem.id}/expanded`
			).then(response => {
				const data = response.data;
				resolve(data);
			}).catch(reject);
		});
	}

	updateTitle() {
		this.treeItem.title = (
			<PmPlanContextMenu node={this}>
				<NodeTitle
					text={<TitleTextFromTreeItem treeItem={this.treeItem} color={NODE_COLORS.PM_PLAN}/>}
					icon={<PmPlanIcon/>}
					treeItem={this.treeItem}
				/>
			</PmPlanContextMenu>
		);
	}
}
