import {Layout, Row, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useHistory, useLocation} from "react-router-dom";
import Input from "antd/es/input/Input";
import {useEffect, useState} from "react";
import {
	About,
	ContactUsLink,
	ContactUsModal,
	CurrentUserAvatar,
	GuideLink,
	HomeLink,
	LogOut,
	OtherResources
} from "@digatex/digatex-ui-lib";
import {EhApi} from "../../../services";
import {Revision} from "../../revision";

function sendEmail(formData) {
	return EhApi.post(`/feedback/contact_us`, formData);
}

export function Header() {

	const [showContactForm, setShowContactForm] = useState(false)

	return (
		<Layout.Header style={{padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
			<HomeLink/>
			<SearchInput/>
			<div style={{marginLeft: 'auto'}}>
				<Row>
					<Space size={"middle"}>
						<About/>
						<OtherResources/>
						<GuideLink url={process.env.PUBLIC_URL + '/files/1000-100-DAT-00011 08 Quick reference guide - DIGATEX Equipment Hub.pdf'}/>
						<ContactUsLink show={() => setShowContactForm(true)}/>
						<LogOut />
						<CurrentUserAvatar/>
						<Revision/>
					</Space>
				</Row>
			</div>
			<ContactUsModal show={showContactForm} close={() => setShowContactForm(false)} sendEmail={sendEmail}/>
		</Layout.Header>
	)
}


function SearchInput() {
	const [searchQuery, setSearchQuery] = useState("");
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		setSearchQuery(new URLSearchParams(location.search).get('q') ?? "");
	}, [location]);

	const handleSearch = () => {
		const current = new URLSearchParams(location.search);
		current.set("q", searchQuery);
		current.set("page", "1");
		history.push({
			pathname: '/search',
			search: current.toString()
		});
	}

	return (
		<Input.Search
			id="global-search-box"
			enterButton={(
				<a onClick={handleSearch}><SearchOutlined/></a>
			)}
			style={{width: '20%', marginLeft: '32px'}}
			value={searchQuery}
			onPressEnter={handleSearch}
			autoComplete="off"
			onChange={e => {
				setSearchQuery(e.target.value)
			}}
		/>
	);
}