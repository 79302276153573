import {Checkbox, Popover, Space, Tooltip} from "antd";
import {useContext} from "react";
import {HierarchyAppearanceContext} from "../../../contexts/hierarchy-appearance-config/HierarchyAppearanceContext";
import {SettingsButton} from "../../buttons";


const defaultTrigger = (
	<SettingsButton/>
);


export function HierarchySettings({origin = defaultTrigger, ...rest}) {
	return (
		<Popover {...rest} trigger={["click"]} title={"Settings"} content={<AppearanceSettings/>} placement={"bottom"}>
			<Tooltip title={"Settings"}>
				{origin}
			</Tooltip>
		</Popover>
	);
}


function AppearanceSettings() {
	const settings = useContext(HierarchyAppearanceContext);

	const handleShowDescription = checked => {
		settings.withUpdatedDescription({show: checked}).saveChanges();
	}

	const handleShowConstructionType = checked => {
		settings.withUpdatedConstructionType({show: checked}).saveChanges();
	}

	return (
		<div style={{width: "188px"}}>
			<Space direction={"vertical"}>
				<Checkbox
					checked={settings.showDescription()}
					onChange={e => handleShowDescription(e.target.checked)}
				>
					Show Description
				</Checkbox>
				<Checkbox
					checked={settings.showConstructionType()}
					onChange={e => handleShowConstructionType(e.target.checked)}
				>
					Show Construction Type
				</Checkbox>
			</Space>
		</div>
	);
}
